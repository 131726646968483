var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-7"},[_c('v-card-title',[_c('div',[_c('span',[_vm._v("SKU List")]),(_vm.product.has_package)?_c('v-chip',{staticClass:"ml-2"},[_vm._v(" Package ")]):_vm._e()],1),_c('v-spacer'),_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({
          name: 'sku-create-resource',
          query: { product_id: _vm.product.id }
        })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add an SKU")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.product.skus,"items-per-page":99,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('main-media',{staticClass:"img img-thumbnail mr-2",attrs:{"media":_vm.product.media,"sku-id":item.id}}),_c('div',[_c('div',{staticClass:"mb-2 mt-2"},[_c('b',[_vm._v(_vm._s(item.name.en))])]),(item.quota_rule && item.quota_rule.rechargeable)?_c('router-link',{attrs:{"to":{ name: 'product-view', query: _vm.$route.query, params: { id: item.quota_rule.rechargeable.product_id } }}},[(item.id === item.quota_rule.rechargeable.id)?_c('span',[_vm._v("(self)")]):_c('span',[_vm._v("(topup)")])]):_vm._e()],1)],1)]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status === 'published' && item.active ? 'success' : 'secondary'}},[_vm._v(" "+_vm._s(item.status === 'published' ? '' : item.status)+" "+_vm._s(item.active ? 'active' : 'disabled')+" ")])]}},{key:"item.stock",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.stock)+" ")]}},{key:"item.quota_rule_id",fn:function(ref){
        var item = ref.item;
return [(item.quota_rule)?_c('div',[_vm._v(" "+_vm._s(item.quota_rule.quota)+" ")]):_vm._e()]}},{key:"item.activity_rule_id",fn:function(ref){
        var item = ref.item;
return [(item.activity_rule)?_c('div',[_vm._v(" "+_vm._s(item.activity_rule.max_participant)+" ")]):_vm._e()]}},{key:"item.language",fn:function(ref){
        var item = ref.item;
return _vm._l((item.languages),function(lang){return _c('v-chip',{key:lang.id,attrs:{"x-small":""}},[_vm._v(" "+_vm._s(lang.name.en.substr(0, 3))+" ")])})}},{key:"item.branch",fn:function(ref){
        var item = ref.item;
return [(item.location)?_c('v-chip',{key:item.location.uuid,staticClass:"ml-s",attrs:{"x-small":""}},[_vm._v(" [MAIN] "+_vm._s(item.location.name.en)+" ")]):_vm._e(),_vm._l((item.branches),function(branch){return _c('v-chip',{key:branch.uuid,staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(branch.name.en)+" ")])})]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.asideSku = item}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),(item.status !== 'published')?_c('v-list-item',{attrs:{"color":"success"},on:{"click":function($event){_vm.publishSku(item.id).then(function () { return _vm.$emit('updated'); })}}},[_c('v-list-item-title',[_c('span',[_vm._v("Publish")])])],1):_vm._e(),(item.status === 'published')?_c('v-list-item',{attrs:{"color":"error"},on:{"click":function($event){_vm.unpublishSku(item.id).then(function () { return _vm.$emit('updated'); })}}},[_c('v-list-item-title',[_c('span',[_vm._v("Unpublish")])])],1):_vm._e(),(item.status === 'draft')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.confirmTrashSku(item).then(function () { return _vm.$emit('updated'); })}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Trash")])],1)],1):_vm._e()],1)],1)]}}],null,true)})],1),(_vm.asideSku)?_c('sku-update-aside',{attrs:{"active":true,"sku-id":_vm.asideSku.id,"product":_vm.product},on:{"changed":function (sku) { _vm.closeAside(false); _vm.$emit('updated') },"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.$emit('updated') }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }