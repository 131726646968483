var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.aside === 'slot_rule')?_c('slot-rule-aside',{attrs:{"active":true,"sku":_vm.sku,"resource":_vm.slotRuleData},on:{"changed":_vm.refresh,"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.refresh() }}}):_vm._e(),(_vm.aside === 'generator')?_c('slot-rule-generator-aside',{attrs:{"active":true,"sku":_vm.sku,"resource":_vm.slotRuleData},on:{"changed":_vm.refresh,"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.refresh() }}}):_vm._e(),_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.sku.name.en)+"'s Slot Auto Generation Rules")]),_c('v-spacer'),(['service', 'activity', 'topup'].includes(_vm.product.nature) || _vm.slotRules.length === 0)?_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleRuleAside(null, 'slot_rule')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add Generation Rule")])],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.slotRules,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.sort",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.sort)+" ")])]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.stock)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{attrs:{"color":item.active ? 'success' : ''}},[_vm._v(" "+_vm._s(item.active ? 'active' : 'inactive')+" ")])],1)]}},{key:"item.days",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.formatDaysInWeek(item.days_in_week))+" ")])]}},{key:"item.dm",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.duration_minutes)+" (+"+_vm._s(item.gap_after_minutes)+") mins ")])]}},{key:"item.fromto",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm._f("hourToString")(item.start_from_hour))+" - "+_vm._s(_vm._f("hourToString")(item.end_to_hour))+" ")])]}},{key:"item.price_group",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.t(item.price_group.name))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleRuleAside(item, 'slot_rule')}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Update Rule")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleRuleGenerator(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCog)+" ")]),_c('span',[_vm._v("Generate Manually")])],1)],1)],1)],1)]}}],null,true)})],1)],1),_c('v-card',[_c('v-card-title',[_vm._v(" Filter Time Slots ")]),_c('v-card-text',[(_vm.ready)?_c('slot-previewer',{attrs:{"rule":null,"sku":_vm.sku,"product":_vm.product,"price-groups":_vm.priceGroups},on:{"updated":_vm.refresh}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }