var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[(_vm.aside === 'voucher')?_c('voucher-aside',{attrs:{"active":true,"sku":_vm.sku,"resource":_vm.voucher},on:{"updated":_vm.emitUpdated,"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.refresh() }}}):_vm._e()],1),_c('v-col',{attrs:{"cols":12}},[_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.vouchers,"options":_vm.options,"server-items-length":_vm.vouchers.length,"loading":_vm.loading,"height":400,"show-select":"","hide-default-footer":"","fixed-header":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.from",fn:function(ref){
var item = ref.item;
return [(item.applicable_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.applicable_at)))]):_c('span',[_vm._v("Any time.")])]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [(item.last_purchasable_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.last_purchasable_at)))]):_c('span',[_vm._v("Never")])]}},{key:"item.expire",fn:function(ref){
var item = ref.item;
return [(item.expires_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.expires_at)))]):_c('span',[_vm._v("Never expires")])]}},{key:"item.redeemed_at",fn:function(ref){
var item = ref.item;
return [(item.redeemed_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.redeemed_at)))]):_c('span',[_vm._v("Not used")])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" ")]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [(item.owner)?_c('span',[_vm._v(_vm._s(item.owner.email))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('span',[_vm._v(_vm._s(item.status))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleAside(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Update")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.resendVoucher(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}}),_c('span',[_vm._v("Resend to...")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.trashVoucher(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1),_c('v-col',[(_vm.selectedRows.length)?_c('v-btn',{attrs:{"color":"error","loading":_vm.loading},on:{"click":_vm.bulkTrashSelected}},[_vm._v(" Bulk Delete ")]):_vm._e()],1),(_vm.sendVoucher)?_c('resend-voucher-dialog',{attrs:{"show":true,"voucher-id":_vm.sendVoucher.id,"default-email":_vm.defaultEmail},on:{"close":function($event){_vm.sendVoucher = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }